import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus';
import * as ElIcons from '@element-plus/icons'
import 'element-plus/lib/theme-chalk/index.css';
import base from './uitils/common'
import http from './api/http'
import 'dayjs/locale/zh-cn'

const app = createApp(App);
app.config.globalProperties.base = base;
app.config.globalProperties.http = http;
app.config.globalProperties.$tabs = {};

app.use(store)
    .use(ElementPlus, {size: 'medium'})
    .use(router)
    .mount('#app');


for (const name in ElIcons){
	app.component(name,ElIcons[name]);
}

app.config.globalProperties.$Message = app.config.globalProperties.$message;


